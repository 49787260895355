.App {
    text-align: center;
    min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
}

.main_section {
    padding: 60px 0 70px;
    max-width: 860px;
    margin: auto;
    background-color: white;
}

input, textarea {
    font-size: 16px;
}

.header {
    position: fixed;
    left: 0;
    top: 5px;
    width: 100%;
}

ul {
    list-style: none;
    padding-inline-start: 0px;
}

a:link, a:visited {
    text-decoration: none;
    color: black;
}

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

textarea {
    width: 90%;
}

.red {
    color: red;
}

button, input[type=submit] {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #f9f9f9 5%, #96dae4 100%);
	background-color:#f9f9f9;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family: 'Open Sans', sans-serif;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}

button:hover, input[type=submit]:hover {
	background:linear-gradient(to bottom, #96dae4 5%, #f9f9f9 100%);
    background-color: #96dae4;
}

button:active {
	position:relative;
	top:1px;
}

@media screen and (min-width: 1300px) {    
    .header {
        position: fixed;
        left: 0;
        top: unset;
        width: unset;
        height: 100%;
    }

    .footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    
    .main_section {
        max-width: unset;
        padding: 20px 20px 70px 350px;
        background-color: #ffedc6;
    }
}