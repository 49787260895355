.header {
    z-index: 99;
}

.header_nav {
    padding: 10px;
    background-color: white;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30);
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30);
}

.header_nav, .header_nav_right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header_nav_right {
    width: 40%;
    font-size: 24px;
}

.header_nav_right .highlighted {
    border-bottom: solid 2px #96dae4;
}

.header_nav_lead, .header_nav_icon, .header_nav_close {
    color: #fbac91;
    font-size: 2em;
}

.header_nav .header_nav_icon, .header_nav .header_nav_close  {
    display: none;
}

.header_freddie_icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .header_nav {
        overflow: hidden;
    }

    .header_nav > .header_nav_right, .header_nav_close {
        display: none;
    }

    .header_nav > .header_nav_icon {
        display: unset;
    }
}

/* handles when menu is toggled open */
@media screen and (max-width: 600px) {
    .header_nav.toggled > .header_nav_icon {
        display: none;
    }

    .header_nav.toggled > .header_nav_close {
        display: unset; 
    }


    .header_nav.toggled {
        flex-wrap: wrap;
        background: rgb(255,255,255);
        background: -moz-linear-gradient(0deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,1) 50%);
        background: -webkit-linear-gradient(0deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,1) 50%);
        background: linear-gradient(0deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,1) 50%);
    }

    .header_nav.toggled .header_nav_right {
        display: flex !important;
        flex-direction: column;
        margin-top: 10px;
        width: 100vw;
        order: 3;
        text-align: center;
    }

    .header_nav.toggled .header_nav_right a {
        padding: 20px 0;
        border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
        width: 80%;
    }
}

@media screen and (min-width: 1300px) {
    .header_nav {
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
    }

    .header_nav_right {
        height: 65%;
        font-size: 40px;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .header_nav_lead {
        display: none;
    }

    .header_freddie_icon {
        display: unset;
    }
}
