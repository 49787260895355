.services_main_title {
    font-family: 'brushy';
    line-height: 1;
    margin-top: 40px;
}

.services_main_title .brushy_swash {
    color: #a1f7a1;
    font-size: 26px;
}

.services_image {
    height: 150px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30), 0px -3px 3px 0px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30), 0px -3px 3px 0px rgba(0,0,0,0.30);
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30), 0px -3px 3px 0px rgba(0,0,0,0.30);
}

.services_day .services_image {
    background-image: url('../../utils/images/german.jpg');
}

.services_overnight .services_image {
    background-image: url('../../utils/images/freddie_laying.jpg');
}

.services_info .services_image {
    background-image: url('../../utils/images/tail_wag.jpg');
}

.services_title {
    font-family: 'Lora', serif;
}

.services_type {
    padding: 0 10px 10px;
    display: flex;
    justify-content: space-around;
    font-family: 'Open Sans', sans-serif;
    border-top: #ffa0a3 4px solid;
}

.services_details {
    width: 60%;
    padding-right: 10px;
    border-right: #ffa0a3 2px solid;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

.services_info .services_details {
    width: unset;
    border-right: none;
    padding: 0 10px;
    border-top: #a1f7a1 4px solid;
}

.services_pricing {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    justify-content: space-between;
    min-height: 60%;
}

@media screen and (min-width: 600px) {
    .services_day, .services_info {
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    .services_overnight {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 10px;
    }

    .services_image {
        min-width: 44%;
        margin: 0 2%;
        height: unset;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30), 0px -3px 3px 0px rgba(0,0,0,0.30);
        -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30), 0px -3px 3px 0px rgba(0,0,0,0.30);
        box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30), 0px -3px 3px 0px rgba(0,0,0,0.30);
    }

    .services section {
        padding: 40px 10px;
    }
}

    
@media screen and (min-width: 1300px) {
    .services {
        background: white;
        padding: 10px;
        -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30);
        -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30);
        box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.30);
    }

    .services_main_title {
        font-size: 42px;
    }

    .services_main_title .brushy_swash {
        font-size: 44px;
    }

    .services_title {
        font-size: 32px;
    }

    .services_image {
        height: 400px;
    }
}