.footer_nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 15px;
    background:#ffa0a3;
    -webkit-box-shadow: 0px -1px 3px 0px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px -1px 3px 0px rgba(0,0,0,0.30);
    box-shadow: 0px -1px 3px 0px rgba(0,0,0,0.30);
}

.footer_contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 35%;
}

.footer_icons {
    font-size: 2em;
}

@media screen and (min-width: 1300px) {
    .footer_nav {
        margin-left: 330px;
    }
}